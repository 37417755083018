import { ILayerColor } from '../designer/layer/LayersState';
import { isRgbaEqual } from './isRgbaEqual';

/**
 * Returns true if both colors have the same RGBA value.
 *
 * If `considerPaletteIndex` is `true` also ensures both colors reference the same palette index otherwise they will
 * be considered different.
 */
export function isLayerColorEqual(
  a: ILayerColor | null | undefined,
  b: ILayerColor | null | undefined,
  considerPaletteIndex = true,
) {
  if (!a && !b) {
    return true;
  }
  if ((a && !b) || (!a && b)) {
    return false;
  }
  if (!isRgbaEqual(a!.rgba, b!.rgba)) {
    return false;
  }
  if (considerPaletteIndex) {
    return a!.paletteIndex === b!.paletteIndex;
  }
  return true;
}
