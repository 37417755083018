import { IDesignProject, ISaveDesignProduct, ISaveDesignProject } from '../IDesignProject';
import { IProject } from '../ProjectState';
import { IProduct, ProductType } from '../ProductState';
import {
  DesignProductType,
  IDesignProduct,
  ITemplateObject,
  ITextTemplateObject,
} from '../IDesignProduct';
import { ILayer, ITextLayer, LayerType, TextTemplateType } from '../../layer/LayersState';
import { LayerCompat } from './LayerCompat';
import { IsoLanguageCode, isoToLegacyLanguageCode } from '../../../locale/language';

const DYNAMIC_TEMPLATE_TYPES = [
  TextTemplateType.Author,
  TextTemplateType.AuthorProfileText,
  TextTemplateType.BookTitle,
  TextTemplateType.BookSubtitle,
  TextTemplateType.Imprint,
];

export class ProjectCompat {
  public static toState(project: IDesignProject, language: IsoLanguageCode): IProject {
    const products = project.products.map<IProduct>((product) => {
      return ProjectCompat.designProductToState(project, product, language);
    });
    // sort the list of products so hard cover always comes first
    products.sort((_, next) => (next.type === ProductType.HardCover ? 1 : 0));

    return {
      id: project.id,
      authorsSeparated: project.authorsSeparated || '',
      title: project.title || '',
      subtitle: project.subtitle || '',
      imprintText: project.imprintText || '',
      authorProfileText: project.authorProfileText || '',
      products,
      activeProduct: ProjectCompat.determineActiveProduct(products),
      isReadonly: project.isReadonly,
      firstCreatedAt: project.firstCreatedAt,
    };
  }

  public static designProductToState(
    project: IDesignProject | IProject,
    product: IDesignProduct,
    language: IsoLanguageCode,
  ): IProduct {
    return {
      id: product.id,
      type: (() => {
        switch (product.type) {
          case DesignProductType.SoftCover:
            return ProductType.SoftCover;
          case DesignProductType.HardCover:
            return ProductType.HardCover;
          case DesignProductType.Ebook:
            return ProductType.Ebook;
          case DesignProductType.LargePrint:
            return ProductType.LargePrint;
          default:
            return ProductType.Invalid;
        }
      })(),
      measures: product.measures,
      regions: product.regions,
      sheets: product.sheets.map(($) => ({
        id: $.id,
        layers: ProjectCompat.getLayers(project, $.objects, language),
      })),
      activeSheetId: product.sheets[0]?.id || null,
      palette: product.palette,
      barcode: product.barcode,
    };
  }

  public static determineActiveProduct(products: IProduct[]): string | null {
    if (!products.length) {
      return null;
    }
    const hardCover = products.find(($) => $.type === ProductType.HardCover);
    if (hardCover) {
      return hardCover.id;
    }
    return products[0].id;
  }

  public static toSaveData(project: IProject): ISaveDesignProject {
    const data: ISaveDesignProject = {
      products: [],
    };

    for (const product of project.products) {
      data.products.push(ProjectCompat.designProductToSaveData(product));
    }
    return data;
  }

  public static designProductToSaveData(product: IProduct): ISaveDesignProduct {
    return {
      id: product.id,
      paletteId: product.palette?.id || null,
      sheets: product.sheets.map((sheet) => ({
        id: sheet.id,
        objects: sheet.layers
          .map(LayerCompat.layerToTemplateObject)
          .filter(($): $ is ITemplateObject => !!$),
        html: '', // NOTE: this is set in the saveProject thunk
      })),
      barcode: product.barcode,
    };
  }

  public static getLayers(
    project: IDesignProject | IProject,
    objects: ITemplateObject[],
    language: IsoLanguageCode,
  ): ILayer[] {
    const layers: ILayer[] = [];
    for (const templateObject of objects) {
      const layer = LayerCompat.templateObjectToLayer(templateObject);
      if (!layer) {
        continue;
      }
      if (layer.type === LayerType.Text) {
        const textLayer = layer as ITextLayer;
        const textObject = templateObject as ITextTemplateObject;

        if (DYNAMIC_TEMPLATE_TYPES.includes(textLayer.template)) {
          textLayer.content = ProjectCompat.getDynamicText(project, textLayer) || '';
        } else {
          textLayer.content = textObject.text || '';
        }
        // for some reason when removing all text from a regular text layer in the old designers
        // they set the content to "<br>" which signals the "placeholders" to take over. yes, really.
        const isDanielEmpty = !textLayer.content || textLayer.content === '<br>';
        if (isDanielEmpty && textLayer.placeholders.length) {
          const legacyCode = isoToLegacyLanguageCode(language);
          const ph =
            textLayer.placeholders.find(($) => $.languageCode === legacyCode) ||
            textLayer.placeholders[0];
          textLayer.content = ph.text || '';
        }

        // need to remove template as soon as we generate HTML for CKE
        textLayer.template = TextTemplateType.None;

        if (textLayer.content) {
          const tmp = document.createElement('div');
          tmp.innerHTML = textLayer.content;
          textLayer.content = tmp.textContent || tmp.innerText || '';
        }
      }
      layers.push(layer);
    }
    return layers.sort((a, b) => a.depth - b.depth);
  }

  private static getDynamicText(
    project: IDesignProject | IProject,
    layer: ITextLayer,
  ): string | null {
    switch (layer.template) {
      case TextTemplateType.None:
      default:
        return null;
      case TextTemplateType.Author:
        return project.authorsSeparated;
      case TextTemplateType.AuthorProfileText:
        return project.authorProfileText;
      case TextTemplateType.BookTitle:
        return project.title;
      case TextTemplateType.BookSubtitle:
        return project.subtitle;
      case TextTemplateType.Imprint:
        return project.imprintText;
    }
  }
}
