import {
  DesignProblemLevel,
  DesignProblemType,
  ITextContentClippingDesignProblem,
} from './IDesignerProblem';
import { ITextLayer } from '../layer/LayersState';
import { getLayerDomId } from '../layer/getLayerDomId';

export function checkTextLayerClipping(
  layer: ITextLayer,
): ITextContentClippingDesignProblem | null {
  const content = document.querySelector(`#${getLayerDomId(layer.id)} .content`);
  if (!content) {
    return null;
  }
  const deltaX = Math.abs(content.scrollWidth - content.clientWidth);
  const deltaY = Math.abs(content.scrollHeight - content.clientHeight);
  const tolerance = 1; // note: when using text align: justify in text layers for some reason the scrollWidth sometimes is 1px less than the clientWidth
  if (deltaX <= tolerance || deltaY <= tolerance) {
    return null;
  }
  return {
    type: DesignProblemType.TextContentClipping,
    level: DesignProblemLevel.Warning,
  };
}
