import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC<PropsWithChildren<PassthruProps>> = ({ children, target }) => {
  if (!target) {
    return null;
  }
  return createPortal(children, target);
};

type PassthruProps = {
  target: HTMLElement | null;
};
