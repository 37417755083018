import { RootState } from '../../redux/store';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { ILayer, ITextLayer, LayerType } from './LayersState';
import { useAppSelector } from '../../redux/hooks';
import { ILiveSheetState, IRulers, TransformationType } from './ILiveSheetState';
import { defaultRulers, liveSheetSlice } from './liveSheetSlice';
import { getDesignerSlice } from '../designerSelectors';
import { createSelector } from '@reduxjs/toolkit';

/**
 * Returns the entire layers state slice
 */
export const getLiveSheetSlice = (state: RootState): ILiveSheetState =>
  getDesignerSlice(state)[liveSheetSlice.name];

/**
 * Returns all existing layers of the cover designer
 */
export const getAllLayers = (state: RootState): ILayer[] => getLiveSheetSlice(state).layers;

/**
 * Returns a layer by id or undefined if not found
 */
export const getLayerById = (state: RootState, id: string): ILayer | null =>
  lookupLayerByIdReducer(getLiveSheetSlice(state), id);

export const getSnap = (state: RootState): IRulers => {
  const liveSheet = getLiveSheetSlice(state);
  if (
    !liveSheet ||
    !liveSheet.transform ||
    liveSheet.transform.type !== TransformationType.Translate
  ) {
    return defaultRulers;
  }
  return liveSheet.transform.rulers;
};

/**
 * Returns all selected layers
 */
export const getSelectedLayers = (state: RootState): ILayer[] =>
  getAllLayers(state).filter(($) => $.isSelected);

/**
 * Returns the text layer which is currently being edited which is only the case if exactly
 * one text layer is selected
 */
export const getEditLayer = (state: RootState): ITextLayer | null => {
  const { layers } = getLiveSheetSlice(state);
  const textLayers = layers.filter(
    ($): $ is ITextLayer => $.isSelected && $.type === LayerType.Text,
  );
  return textLayers.length === 1 ? textLayers[0] : null;
};

/**
 * Provides the layer with the given id to a React component
 */
export const useLayerById = (id: string): ILayer | null =>
  useAppSelector((s) => getLayerById(s, id));

/**
 * Provides all layers to a React component
 */
export const useAllLayers = () => useAppSelector(getAllLayers);

/**
 * Memoized selector which provides all selected layers
 */
const selectedLayersSelector = createSelector([getAllLayers], (layers) =>
  layers.filter(($) => $.isSelected),
);

/**
 * Provides all selected layers to a React component
 */
export const useSelectedLayers = () => useAppSelector(selectedLayersSelector);

/**
 * Provides the layer snap configuration to a React component
 */
export const useSnap = () => useAppSelector(getSnap);

export const useLiveSheet = () => useAppSelector(getLiveSheetSlice);

/**
 * Provides the info whether any layer transform is ongoing to a React component
 */
export const useIsTransformingLayers = () => useAppSelector(getLiveSheetSlice).transform !== null;

/**
 * Provides the text layer which is currently being edited to a React component
 */
export const useEditLayer = () => useAppSelector(getEditLayer);
