import {
  ChangeEventHandler,
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ILayerProps, Layer } from './Layer';
import { ITextLayer, TextAlign } from './LayersState';
import { px } from '../../lib/px';
import { getLayerCssFilter } from './getLayerCssFilter';
import { getCssRgba, getLayerBackgroundColorCss, getLayerBorderCss } from '../../lib/Color';
import { Portal } from '../../util/Portal';
import { useAppDispatch } from '../../redux/hooks';
import { editTextLayer } from './liveSheetSlice';
import { stopPropagation } from '../../lib/stopPropagation';

export interface ITextLayerProps extends ILayerProps {
  layer: ITextLayer;
}

export const TextLayer: FC<ITextLayerProps> = ({ layer }) => {
  const contentRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useAppDispatch();
  const { id, isEditing } = layer;

  useEffect(() => {
    const content = contentRef.current;
    if (isEditing && content && document.activeElement !== content) {
      content.focus();
    }
  }, [isEditing]);

  const onBlur = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (!e.target) {
        return;
      }

      dispatch(
        editTextLayer({
          id,
          text: e.target.value ?? '',
          isEditing: false,
        }),
      );
    },
    [id, dispatch],
  );

  const styles = useMemo<Record<string, CSSProperties>>(() => {
    return {
      layer: getLayerStyle(layer),
      content: getTextLayerContentStyle(layer),
    };
  }, [layer]);

  const render = (
    <Layer layer={layer} className="text" style={styles.layer}>
      {layer.isEditing ? (
        <textarea
          ref={contentRef}
          style={styles.content}
          defaultValue={layer.content}
          onPointerDown={layer.isEditing ? stopPropagation : undefined}
          onBlur={layer.isEditing ? onBlur : undefined}
        />
      ) : (
        <div className="content" style={styles.content}>
          {layer.content}
        </div>
      )}
    </Layer>
  );

  if (layer.isEditing) {
    const target = document.getElementById('topmost-layer');
    return <Portal target={target}>{render}</Portal>;
  }

  return render;
};

function getLayerStyle(layer: ITextLayer): CSSProperties {
  return {
    backgroundColor: getLayerBackgroundColorCss(layer),
    border: getLayerBorderCss(layer),
    overflow: 'hidden',
    pointerEvents: 'auto',
  };
}

function getTextLayerContentStyle(layer: ITextLayer): CSSProperties {
  if (!layer) {
    return {};
  }

  const { formatting } = layer;
  const color = formatting.color.foreground?.rgba
    ? getCssRgba(formatting.color.foreground!.rgba)
    : undefined;
  /*
  text background not supported since CKE removal
  const background = formatting.color.background?.rgba
    ? getCssRgba(formatting.color.background!.rgba)
    : 'transparent';
   */
  const background = 'transparent';

  const style: CSSProperties = {
    padding: px(layer.padding),
    margin: 0,
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden !important',
    color,
    background,
    border: 'none',
    fontFamily: formatting.fontFamily,
    fontWeight: formatting.bold ? 'bold' : undefined,
    fontSize: formatting.fontSize,
    letterSpacing: formatting.letterSpacing,
    lineHeight: formatting.lineHeight,
    whiteSpace: 'pre-wrap',
    textAlign: (() => {
      switch (formatting.align) {
        case TextAlign.Left:
          return 'left';
        case TextAlign.Center:
          return 'center';
        case TextAlign.Right:
          return 'right';
        case TextAlign.Justify:
          return 'justify';
        default:
          return undefined;
      }
    })(),
    textTransform: formatting.uppercase ? 'uppercase' : undefined,
    textDecoration: formatting.underline ? 'underline' : undefined,
    filter: getLayerCssFilter(layer),
  };

  // apply style to text element
  switch (formatting.align) {
    case TextAlign.Left:
      style.justifyContent = 'flex-start';
      break;
    case TextAlign.Center:
      style.justifyContent = 'center';
      break;
    case TextAlign.Right:
      style.justifyContent = 'flex-end';
      break;
  }

  if (layer.rotateForBookBack) {
    style.transform = 'rotate(-90deg) translateX(-100%)';
    style.transformOrigin = 'left top';
    style.width = px(layer.display.height);
    style.height = px(layer.display.width);
  }

  if (layer.isEditing) {
    style.outline = '1px dashed yellow';
    style.outlineOffset = '-1px';
  }

  return style;
}
