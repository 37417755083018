import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ITextLayer, LayerType, TextTemplateType } from './LayersState';
import { ILiveSheetState } from './ILiveSheetState';

export interface IEditTextLayerActionPayload {
  /**
   * Id of the layer to edit. Must be a text layer
   */
  id: string;

  /**
   * The new value of the `isEditing` attribute
   */
  isEditing: boolean;

  /**
   * An optional text to assign. Ignored if `undefined` but may be set to `null`.
   */
  text?: string;
}

export function editTextLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IEditTextLayerActionPayload>,
) {
  const textLayer = state.layers.find(
    ($) => $.id === action.payload.id && $.type === LayerType.Text,
  ) as ITextLayer;
  if (!textLayer) {
    return;
  }
  if (action.payload.isEditing) {
    state.layers.forEach((layer) => {
      if (layer.type === LayerType.Text) {
        (layer as ITextLayer).isEditing = false;
      }
    });
  }
  textLayer.isEditing = action.payload.isEditing;
  if (typeof action.payload.text !== 'undefined') {
    // overwriting the text of a text template will remove the template
    textLayer.template = TextTemplateType.None;
    textLayer.content = action.payload.text;
    textLayer.updatedAt = Date.now();
  }
}
