import { createSlice } from '@reduxjs/toolkit';
import { deselectLayerReducer } from './deselectLayerReducer';
import { deselectLayersReducer } from './deselectLayersReducer';
import {
  addImageLayerReducer,
  addLayersReducer,
  addPlainLayerReducer,
  addTextLayerReducer,
} from './addLayerReducers';
import { grabLayerReducer } from './grabLayerReducer';
import { releaseLayersReducer } from './releaseLayersReducer';
import { selectLayerReducer } from './selectLayerReducer';
import { scaleLayersReducer } from './scaleLayersReducer';
import { selectLayersInAreaReducer } from './selectLayersInAreaReducer';
import { moveLayersReducer } from './moveLayersReducer';
import { setTextContentReducer } from './setTextContentReducer';
import { selectAllLayersReducer } from './selectAllLayersReducer';
import { setImageDimensionsReducer } from './setImageDimensionsReducer';
import { deleteLayersReducer } from './deleteLayersReducer';
import { setLayerErrorReducer } from './setLayerErrorReducer';
import { reloadImageLayerReducer } from './reloadImageLayerReducer';
import { setLayersReducer } from './setLayersReducer';
import { setEffectReducer } from './setEffectReducer';
import { adjustDepthReducer } from './adjustDepthReducer';
import { formatTextLayerReducer } from './formatTextLayerReducer';
import { applyPaletteToLayersReducer } from './applyPaletteToLayersReducer';
import { applySetProjectToLayersReducer } from './applySetProjectToLayersReducer';
import { setBorderReducer } from './setBorderReducer';
import { setBackgroundReducer } from './setBackgroundReducer';
import { rotateLayersReducer } from './rotateLayersReducer';
import { setImageOptionsReducer } from './setImageOptionsReducer';
import { applyInputToLayersReducer } from './applyInputToLayersReducer';
import { restoreFromHistory } from '../history/historySlice';
import { applyRestoreFromHistoryToLayersReducer } from './applyRestoreFromHistoryToLayersReducer';
import {
  activateSheetInternal,
  addSheet,
  applyTemplateInternal,
  clearProject,
  deleteSheetInternal,
  importProductInternal,
  openProductInternal,
  setPaletteInternal,
  setProject,
  setReadonly,
} from '../project/projectActions';
import { ILiveSheetState, IRulers } from './ILiveSheetState';
import { applyOpenProductToLayersReducer } from './applyOpenProductToLayersReducer';
import { applyActivateSheetToLayersReducer } from './applyActivateSheetToLayersReducer';
import { applyDeleteSheetToLayersReducer } from './applyDeleteSheetToLayersReducer';
import { applyAddSheetToLayersReducer } from './applyAddSheetToLayersReducer';
import { setInput } from '../input/inputSlice';
import { pasteLayersReducer } from './pasteLayersReducer';
import { selectLayersReducer } from './selectLayersReducer';
import { applySetReadonlyToLayersReducer } from './applySetReadonlyToLayersReducer';
import { replaceImageReducer } from './replaceImageReducer';
import { replaceColorReducer } from './replaceColorReducer';
import { applyClearProjectToLayersReducer } from './applyClearProjectToLayersReducer';
import { setPaddingReducer } from './setPaddingReducer';
import { setLayersByProductReducer } from './setLayersByProductReducer';
import { editTextLayerReducer } from './editTextLayerReducer';

export const defaultRulers: IRulers = Object.seal({ h: [], v: [] });

export function createInitialLiveSheetState(): ILiveSheetState {
  return {
    layers: [],
    transform: null,
  };
}

export const liveSheetSlice = createSlice({
  name: 'liveSheet',
  initialState: Object.freeze(createInitialLiveSheetState()),
  reducers: {
    addImageLayer: addImageLayerReducer,
    addLayers: addLayersReducer,
    addPlainLayer: addPlainLayerReducer,
    addTextLayer: addTextLayerReducer,
    adjustDepth: adjustDepthReducer,
    deleteLayers: deleteLayersReducer,
    deselectLayer: deselectLayerReducer,
    deselectLayers: deselectLayersReducer,
    editTextLayer: editTextLayerReducer,
    setTextContent: setTextContentReducer,
    formatTextLayer: formatTextLayerReducer,
    grabLayer: grabLayerReducer,
    setImageDimensions: setImageDimensionsReducer,
    moveLayers: moveLayersReducer,
    pasteLayers: pasteLayersReducer,
    releaseLayers: releaseLayersReducer,
    reloadImageLayer: reloadImageLayerReducer,
    replaceImage: replaceImageReducer,
    replaceColor: replaceColorReducer,
    rotateLayers: rotateLayersReducer,
    scaleLayers: scaleLayersReducer,
    selectAllLayers: selectAllLayersReducer,
    selectLayer: selectLayerReducer,
    selectLayersInArea: selectLayersInAreaReducer,
    selectLayers: selectLayersReducer,
    setBackground: setBackgroundReducer,
    setBorder: setBorderReducer,
    setEffect: setEffectReducer,
    setImageOptions: setImageOptionsReducer,
    setLayerError: setLayerErrorReducer,
    setLayers: setLayersReducer,
    setPadding: setPaddingReducer,
  },
  extraReducers: (builder) =>
    builder
      .addCase(addSheet, applyAddSheetToLayersReducer)
      .addCase(deleteSheetInternal, applyDeleteSheetToLayersReducer)
      .addCase(activateSheetInternal, applyActivateSheetToLayersReducer)
      .addCase(setPaletteInternal, applyPaletteToLayersReducer)
      .addCase(setProject, applySetProjectToLayersReducer)
      .addCase(openProductInternal, applyOpenProductToLayersReducer)
      .addCase(importProductInternal, setLayersByProductReducer)
      .addCase(applyTemplateInternal, setLayersByProductReducer)
      .addCase(setInput, applyInputToLayersReducer)
      .addCase(restoreFromHistory, applyRestoreFromHistoryToLayersReducer)
      .addCase(setReadonly, applySetReadonlyToLayersReducer)
      .addCase(clearProject, applyClearProjectToLayersReducer),
});

export const {
  addImageLayer,
  addLayers,
  addPlainLayer,
  addTextLayer,
  adjustDepth,
  deleteLayers,
  deselectLayer,
  deselectLayers,
  editTextLayer,
  setTextContent,
  formatTextLayer,
  grabLayer,
  setImageDimensions,
  moveLayers,
  pasteLayers,
  releaseLayers,
  reloadImageLayer,
  replaceImage,
  replaceColor,
  rotateLayers,
  scaleLayers,
  selectAllLayers,
  selectLayer,
  selectLayersInArea,
  selectLayers,
  setBackground,
  setBorder,
  setEffect,
  setImageOptions,
  setLayerError,
  setLayers,
  setPadding,
} = liveSheetSlice.actions;
