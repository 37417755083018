import {
  IImageLayer,
  ILayer,
  IPlainLayer,
  ITextFormatting,
  ITextLayer,
  LayerError,
  LayerType,
  TextAlign,
  TextTemplateType,
  TextValign,
} from './LayersState';
import { v4 as uuid } from 'uuid';
import { DeepPartial } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { Rgba } from '../../lib/Color';

/**
 * Constructs ready-to-use layers for real-use and testing
 */
export abstract class LayerFactory {
  private static withDefaults<T extends ILayer>(defaults: T, extensions: DeepPartial<T>): T {
    const layer: T = {
      ...LayerFactory.Layer(),
      ...defaults,
    };
    merge(layer, extensions);
    layer.display.x = layer.x;
    layer.display.y = layer.y;
    layer.display.width = layer.width;
    layer.display.height = layer.height;
    layer.display.rotation = layer.transform.rotate;
    return layer;
  }

  /**
   * Creates a text layer for the given default input.
   * If no label it is specified, it will be automatically generated based on the layer id.
   */
  public static TextLayer(layerData: DeepPartial<ITextLayer> = {}): ITextLayer {
    return LayerFactory.withDefaults<ITextLayer>(
      {
        ...LayerFactory.Layer(),
        content: '',
        isEditing: false,
        type: LayerType.Text,
        width: 100,
        height: 32,
        formatting: LayerFactory.DefaultTextFormat(),
        template: TextTemplateType.None,
        placeholders: [],
        rotateForBookBack: false,
      },
      layerData,
    );
  }

  public static DefaultTextFormat(): ITextFormatting {
    return {
      align: TextAlign.Left,
      valign: TextValign.Top,
      bold: false,
      italic: false,
      underline: false,
      uppercase: false,
      fontSize: 12,
      fontFamily: 'Book Antiqua',
      letterSpacing: 0.0,
      lineHeight: 1,
      color: {
        foreground: null,
        background: null,
      },
    };
  }

  /**
   * Creates an image layer for the given default input.
   */
  public static ImageLayer(layerData: DeepPartial<IImageLayer> = {}): IImageLayer {
    return LayerFactory.withDefaults(
      {
        ...LayerFactory.Layer(),
        type: LayerType.Image,
        label: 'Bild',
        imageSize: 100,
        imageRotation: 0,
        keepAspectRatio: true,
        repeat: false,
        replacements: [],
        strokeWidth: 1,
        fileId: '',
      },
      layerData,
    );
  }

  /**
   * Creates a plain layer for the given default input.
   */
  public static PlainLayer(layerData: DeepPartial<IPlainLayer> = {}): IPlainLayer {
    return LayerFactory.withDefaults<IPlainLayer>(
      {
        ...LayerFactory.Layer(),
        type: LayerType.Plain,
        label: 'Box',
        border: {
          size: 2,
          color: { rgba: Rgba.black(), paletteIndex: null },
        },
        width: 100,
        height: 100,
      },
      layerData,
    );
  }

  /**
   * Constructs an object which satisfies the `ILayer` interface. It is recommended to use this factory the following way:
   */
  private static Layer(): ILayer {
    const createdAt = Date.now();

    return {
      id: uuid(),
      error: LayerError.None,
      createdAt,
      updatedAt: createdAt,
      type: LayerType.Invalid,
      depth: 0,
      label: 'Layer',
      isSelected: true,
      isHovered: false,
      isGrabbed: false,
      isDeletable: true,
      isScalable: true,
      isRotatable: true,
      x: 0,
      y: 0,
      keepAspectRatio: false,
      width: -1,
      height: -1,
      hasDimensions: false,
      transform: {
        rotate: 0,
      },
      background: {
        color: null,
      },
      border: {
        size: 0,
        color: null,
      },
      effect: {
        blur: 0,
        hueRotate: 0,
        dropShadow: 0,
        grayscale: 0,
        transparency: 0,
        sepia: 0,
      },
      display: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotation: 0,
      },
      allowEffects: true,
      padding: 0,
    };
  }
}
