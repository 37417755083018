import { DesignerMode } from '../designerMode';
import { treditionApi } from '../../api/treditionApi';

export function getSaveEndpoint(mode: DesignerMode) {
  switch (mode) {
    case DesignerMode.Cover:
      return treditionApi.endpoints.saveCoverDesign;
    case DesignerMode.FrontMatter:
      return treditionApi.endpoints.saveFrontMatterDesign;
    default:
      return treditionApi.endpoints.saveCoverDesign;
  }
}
