import React from 'react';
import styled from 'styled-components';

interface PopconfirmProps {
  message: string;
  onConfirm: () => void;
}

export const CeAlertPopConfirm: React.FC<PopconfirmProps> = ({ message, onConfirm }) => {
  return (
    <Overlay>
      <PopperContainer>
        <Headline>Systemanpassung: Cover prüfen</Headline>
        <Message>{message}</Message>
        <Actions>
          <ConfirmButton
            onClick={() => {
              onConfirm();
            }}>
            Verstanden
          </ConfirmButton>
        </Actions>
      </PopperContainer>
    </Overlay>
  );
};

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Headline = styled.h2`
  padding-bottom: 16px;
`;

const PopperContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 600px;
  line-height: 1.5;
`;

const Message = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  color: #333;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const ConfirmButton = styled.button`
  padding: 8px 16px;
  background-color: #8ce699;
  color: #000000;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9rem;

  &:hover {
    background-color: #79d97c;
  }
`;
