import { FC, useMemo } from 'react';
import { SheetScaffold } from './SheetScaffold';
import { useActiveProduct, useIsReadonly } from '../project/productSelectors';
import { px } from '../../lib/px';
import './Sheet.css';
import { LayerRenderer } from '../layer/LayerRenderer';
import { useDesignerView } from '../view/viewSelectors';
import { ILayer } from '../layer/LayersState';
import { RenderExtensions } from '../renderExtension/RenderExtensions';

export interface ISheetProps {
  isActive: boolean;
  layers: ILayer[];
  index: number;
}

export const Sheet: FC<ISheetProps> = ({ isActive, layers, index }) => {
  const { zoom, mode, showScaffold, showOverflow } = useDesignerView();
  const isReadonly = useIsReadonly();
  const product = useActiveProduct();
  const transform = useMemo(() => {
    const pct = Math.round(zoom * 100);
    // scale(1) for some reason produces a different outcome from not mentioning it at all
    if (pct === 100) {
      return undefined;
    }
    return `scale(${zoom})`;
  }, [zoom]);

  if (!product) {
    return null;
  }
  return (
    <div
      className="sheet"
      style={{
        width: px(product.measures.width),
        height: px(product.measures.height),
        transform,
      }}>
      <div className="render">
        <LayerRenderer layers={layers} showOverflow={showOverflow} isReadonly={isReadonly} />
        <div className="ext">
          <RenderExtensions />
        </div>
      </div>
      {showScaffold && (
        <SheetScaffold
          index={index}
          sheetCount={product.sheets.length}
          isActive={isActive}
          regions={product.regions}
          bleed={product.measures.bleed}
          mode={mode}
        />
      )}
    </div>
  );
};
