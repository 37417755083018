import { createGlobalStyle } from 'styled-components';
import globalStyle from './globalStyle.module.css';

export default createGlobalStyle`
  ${globalStyle}
  
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body, #root, .trc {
    height: 100%;
    overflow: clip;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Barlow', sans-serif;
  }
  
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  
  input, button, textarea, select {
    font: inherit;
  }
  
  figure {
    margin: 0;
  }

  #root, #__next {
    //isolation: isolate;
  }
  
  .trc {
    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
    }
    
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    h2 {
      font-size: 1.1rem;
      font-weight: 600;
    }

    h3 {
      font-size: 0.9rem;
      font-weight: 600;
    }
    
    h4 {
      font-size: 1.2rem;
      font-weight: 500;
    }

    //input[type="number"] {
    //  appearance: textfield;
    //}
    //
    //input[type="text"] {
    //  border: 1px solid var(--color-cta);
    //  border-radius: 2px;
    //  outline: none;
    //  font-weight: 600;
    //  font-size: 1rem;
    //  color: #000000;
    //  padding: .375rem .75rem;
    //}
    //
    //input[type="text"]:hover, input[type="text"]:focus {
    //  background: var(--color-cta-light);
    //}

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
`;
