import styled from 'styled-components/macro';

export interface IButton {
  cancel?: boolean;
}

export const Button = styled.button<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 0.5rem;
  padding: 0 0.5rem;
  height: 2rem;
  border: none;
  background-color: ${(props) =>
    props.cancel ? 'var(--color-background-cancel)' : 'var(--color-cta)'};
  border-radius: var(--border-radius);
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.cancel ? 'var(--color-background-cancel-hover)' : 'var(--color-cta-hover)'};
  }

  &:active {
    background-color: ${(props) =>
      props.cancel ? 'var(--color-background-cancel)' : 'var(--color-cta-light)'};
  }

  &:disabled {
    background-color: var(--color-background-secondary);
    cursor: auto;
  }

  & > svg {
    width: 1rem;
  }
`;
