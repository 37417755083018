import React, { useCallback, useMemo } from 'react';
import { DeepPartial } from '@reduxjs/toolkit';
import classNames from 'classnames';
import {
  ILayerColor,
  ITextFormatting,
  ITextLayer,
  TextAlign,
  TextValign,
} from '../layer/LayersState';
import { LayerMenu } from './LayerMenu';
import { useAppDispatch } from '../../redux/hooks';
import { formatTextLayer } from '../layer/liveSheetSlice';
import { useColorPickerModal } from '../palette/useColorPickerModal';
import { LayerFactory } from '../layer/LayerFactory';
import { useActivePalette } from '../project/productSelectors';
import { useSelectedLayers } from '../layer/liveSheetSelectors';
import { rgbToHex } from '../../lib/rgbToHex';
import { IColor } from '../palette/IColor';
import { useLayerStyleAggregate } from './useLayerStyleAggregate';
import styled from 'styled-components/macro';
import { ReactComponent as Bold } from '../../icons/tredition-bold-control.svg';
import { ReactComponent as Italic } from '../../icons/tredition-italic-control.svg';
import { ReactComponent as Underlined } from '../../icons/tredition-underlined-control.svg';
import { ReactComponent as AlignLeft } from '../../icons/tredition-linksbuendig-control.svg';
import { ReactComponent as AlignCenter } from '../../icons/tredition-zentriert-control.svg';
import { ReactComponent as AlignRight } from '../../icons/tredition-rechtsbuendig-control.svg';
import { ReactComponent as Capitals } from '../../icons/tredition-grossschreibung-control.svg';
import { ReactComponent as Fill } from '../../icons/tredition-fuellfarbe2-control.svg';
import { ReactComponent as PositionTop } from '../../icons/tredition-ausrichtenoben-control.svg';
import { ReactComponent as PositionCenter } from '../../icons/tredition-ausrichtenmitte-control.svg';
import { ReactComponent as PositionBottom } from '../../icons/tredition-ausrichtenunten-control.svg';
import { ReactComponent as Erase } from '../../icons/tredition-radiergummi-control.svg';
import { ReactComponent as LetterSpacing } from '../../icons/tredition-zeichenabstand-control.svg';
import { ReactComponent as TextColor } from '../../icons/tredition-schriftfarbe2-control.svg';
import { Menu } from '../../menu/Menu';
import { MenuItem } from '../../menu/MenuItem';
import { GOOGLE_FONTS } from '../fonts';

const EDITOR_FONT_SIZES = [
  8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 44, 48, 52, 56, 60, 64,
  68, 72, 78, 82, 86, 90, 100, 110, 120,
];

export interface ITextLayerMenuProps {
  layers: ITextLayer[];
}

export const TextLayerMenu: React.FC<ITextLayerMenuProps> = ({ layers }) => {
  const dispatch = useAppDispatch();
  const activePalette = useActivePalette();
  const colorPickerModal = useColorPickerModal();
  const selectedLayers = useSelectedLayers();
  const styleAggregate = useLayerStyleAggregate(selectedLayers);

  const setFormat = (format: DeepPartial<ITextFormatting>) => {
    dispatch(
      formatTextLayer({
        filter: { selected: true },
        format,
      }),
    );
  };

  const resetFormat = useCallback(() => {
    dispatch(
      formatTextLayer({ filter: { selected: true }, format: LayerFactory.DefaultTextFormat() }),
    );
  }, [dispatch]);

  const onPickTextColor = useCallback(
    ({ rgba, paletteIndex }: IColor) => {
      dispatch(
        formatTextLayer({
          filter: { selected: true },
          format: { color: { foreground: { rgba, paletteIndex } } },
        }),
      );
    },
    [dispatch],
  );

  const onPickTextBackgroundColor = useCallback(
    ({ rgba, paletteIndex }: IColor) => {
      dispatch(
        formatTextLayer({
          filter: { selected: true },
          format: { color: { background: { rgba, paletteIndex } } },
        }),
      );
    },
    [dispatch],
  );

  const { fontSizeOptions, fontFamilyOptions } = useMemo(() => {
    return {
      fontSizeOptions: EDITOR_FONT_SIZES.map((v) => (
        <option value={String(v)} key={v}>
          {v}
        </option>
      )),
      fontFamilyOptions: GOOGLE_FONTS.map((v) => (
        <option value={v} key={v}>
          {v}
        </option>
      )),
    };
  }, []);

  if (layers.length === 0) {
    return null;
  }

  const getClassName = (attr: { value: unknown; mixed: boolean }) => {
    return classNames({
      active: attr.value,
      mixed: attr.mixed,
    });
  };

  function cssColorFromAggregatedColor(
    color: ILayerColor | null | undefined,
    defaultValue = '#000000FF',
  ): string {
    if (!color || !color.rgba) {
      return defaultValue;
    }
    return rgbToHex(color.rgba, true);
  }

  return (
    <LayerMenu layers={layers}>
      <Menu>
        <MenuWrapper>
          <MenuItem
            isActive={styleAggregate.bold.value || undefined}
            className={getClassName(styleAggregate.bold)}
            onClick={() => setFormat({ bold: !styleAggregate.bold.value })}>
            <IconWrapper height={0.8}>
              <Bold />
            </IconWrapper>
          </MenuItem>
          <MenuItem
            isActive={styleAggregate.italic.value || undefined}
            className={getClassName(styleAggregate.italic)}
            onClick={() => setFormat({ italic: !styleAggregate.italic.value })}>
            <IconWrapper height={0.8}>
              <Italic />
            </IconWrapper>
          </MenuItem>
          <MenuItem
            isActive={styleAggregate.underline.value || undefined}
            className={getClassName(styleAggregate.underline)}
            onClick={() => setFormat({ underline: !styleAggregate.underline.value })}>
            <IconWrapper height={0.8}>
              <Underlined />
            </IconWrapper>
          </MenuItem>
          <MenuItem
            isActive={styleAggregate.uppercase.value || undefined}
            className={getClassName(styleAggregate.uppercase)}
            onClick={() => setFormat({ uppercase: !styleAggregate.uppercase.value })}>
            <IconWrapper height={0.8}>
              <Capitals />
            </IconWrapper>
          </MenuItem>
          <MenuItem>
            <select
              value={styleAggregate.fontFamily.value || ''}
              onChange={(e) => {
                setFormat({ fontFamily: e.target.value });
              }}>
              {fontFamilyOptions}
            </select>
          </MenuItem>
          <MenuItem className="font-size">
            <select
              value={(styleAggregate.fontSize.value || '').toString()}
              onChange={(e) => {
                const fontSize = Number.parseInt(e.target.value);
                if (!Number.isNaN(fontSize)) {
                  setFormat({ fontSize });
                }
              }}>
              {fontSizeOptions}
            </select>
          </MenuItem>
          <MenuItem
            onClick={() => {
              colorPickerModal.open({
                onPick: onPickTextColor,
                initialColor: styleAggregate.fontColor.value,
                palette: activePalette,
              });
            }}>
            <span className="color">
              <IconWrapper height={0.8}>
                <TextColor />
              </IconWrapper>
              <span
                className="indicator"
                style={{
                  backgroundColor: cssColorFromAggregatedColor(styleAggregate.fontColor.value),
                }}
              />
            </span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              colorPickerModal.open({
                onPick: onPickTextBackgroundColor,
                initialColor: styleAggregate.textBackground.value,
                palette: activePalette,
              });
            }}>
            <span className="color">
              <IconWrapper height={0.8}>
                <Fill />
              </IconWrapper>
              <span
                className="indicator"
                style={{
                  backgroundColor: cssColorFromAggregatedColor(styleAggregate.textBackground.value),
                }}
              />
            </span>
          </MenuItem>
          <MenuItem
            subMenu={() => (
              <Menu>
                <MenuItem onClick={() => setFormat({ align: TextAlign.Left })}>
                  <IconWrapper height={0.8}>
                    <AlignLeft />
                  </IconWrapper>
                </MenuItem>
                <MenuItem onClick={() => setFormat({ align: TextAlign.Center })}>
                  <IconWrapper height={0.8}>
                    <AlignCenter />
                  </IconWrapper>
                </MenuItem>
                <MenuItem onClick={() => setFormat({ align: TextAlign.Right })}>
                  <IconWrapper height={0.8}>
                    <AlignRight />
                  </IconWrapper>
                </MenuItem>
              </Menu>
            )}>
            {(() => {
              if (styleAggregate.align.mixed) {
                return '?';
              }

              switch (styleAggregate.align.value) {
                case TextAlign.Left:
                default:
                  return (
                    <IconWrapper height={0.8}>
                      <AlignLeft />
                    </IconWrapper>
                  );
                case TextAlign.Center:
                  return (
                    <IconWrapper height={0.8}>
                      <AlignCenter />
                    </IconWrapper>
                  );
                case TextAlign.Right:
                  return (
                    <IconWrapper height={0.8}>
                      <AlignRight />
                    </IconWrapper>
                  );
              }
            })()}
          </MenuItem>
          <MenuItem
            subMenu={() => (
              <Menu>
                <MenuItem onClick={() => setFormat({ valign: TextValign.Top })}>
                  <IconWrapper height={0.8}>
                    <PositionTop />
                  </IconWrapper>
                </MenuItem>
                <MenuItem onClick={() => setFormat({ valign: TextValign.Center })}>
                  <IconWrapper height={0.8}>
                    <PositionCenter />
                  </IconWrapper>
                </MenuItem>
                <MenuItem onClick={() => setFormat({ valign: TextValign.Bottom })}>
                  <IconWrapper height={0.8}>
                    <PositionBottom />
                  </IconWrapper>
                </MenuItem>
              </Menu>
            )}>
            {(() => {
              if (styleAggregate.valign.mixed) {
                return '?';
              }

              switch (styleAggregate.valign.value) {
                case TextValign.Top:
                  return (
                    <IconWrapper height={0.8}>
                      <PositionTop />
                    </IconWrapper>
                  );
                case TextValign.Center:
                  return (
                    <IconWrapper height={0.8}>
                      <PositionCenter />
                    </IconWrapper>
                  );
                case TextValign.Bottom:
                  return (
                    <IconWrapper height={0.8}>
                      <PositionBottom />
                    </IconWrapper>
                  );
              }
            })()}
          </MenuItem>
          <MenuItem
            subMenu={() => (
              <Menu>
                <Slider title="Zeichenabstand" value={String(styleAggregate.letterSpacing.value)}>
                  <input
                    type="range"
                    title="Zeichenabstand"
                    min={0}
                    max={1}
                    step={0.01}
                    value={
                      styleAggregate.letterSpacing.mixed
                        ? ''
                        : String(styleAggregate.letterSpacing.value)
                    }
                    onChange={(e) => {
                      const letterSpacing = Number.parseFloat(e.target.value);
                      if (!Number.isNaN(letterSpacing)) {
                        setFormat({ letterSpacing });
                      }
                    }}
                  />
                </Slider>
              </Menu>
            )}>
            <IconWrapper height={0.8}>
              <LetterSpacing />
            </IconWrapper>
          </MenuItem>
          <MenuItem onClick={resetFormat}>
            <IconWrapper height={0.8}>
              <Erase />
            </IconWrapper>
          </MenuItem>
        </MenuWrapper>
      </Menu>
      {colorPickerModal.isOpen && colorPickerModal.render()}
    </LayerMenu>
  );
};

interface ISlider {
  title: string;
  value: string;
  children: React.ReactNode;
}

const Slider: React.FC<ISlider> = ({ title, value, children }) => {
  return (
    <SliderWrapper>
      <Caption>
        <span>{title}</span>
        {value}
      </Caption>
      {children}
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  padding: 0.25rem 0.5rem;
  width: 12rem;
  font-size: 0.9rem;

  & > input[type='range'] {
    width: 100%;
    background-color: #cfcfcf;
    accent-color: var(--color-cta);
  }
`;

const Caption = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IIconWrapper {
  height?: number;
}

const IconWrapper = styled.span<IIconWrapper>`
  background-color: transparent;
  pointer-events: none;

  & > svg {
    pointer-events: none;
    height: ${({ height }) => (height ? `${height}rem` : '1rem')};
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  grid-row: 2 / span 1;
  border-right: 1px solid var(--color-border);
`;
